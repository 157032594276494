import { useState, useEffect } from "react";
import { bd09togcj02, gcj02towgs84, gcj02tobd09 } from "coordtransform";
import useCurrent from "../useCurrent";

export const getBMapAK = (): string => process.env.REACT_APP_BAIDU_MAP_API_KEY;
const scriptID = "baidu-map-script-loader";
let bMapSuccessBacks: (() => void)[] = [];
let bMapErrorBacks: (() => void)[] = [];
let bMapLoaded = false;

export const useBMapApiLoader = (ak: string, disabled?: boolean) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const loadingRef = useCurrent(loading);
  const loadedRef = useCurrent(loaded);
  useEffect(() => {
    if (disabled || loadingRef.current || loadedRef.current) return;
    if (document.getElementById(scriptID)) {
      setLoaded(true);
      setLoading(false);
      setError("");
      return;
    }
    setLoading(true);
    injectBMapService(
      ak,
      () => {
        setLoaded(true);
        setLoading(false);
      },
      () => setError("Load baidu api error.")
    );
    return () => {
      setLoading(false);
      setLoaded(false);
      setError("");
      // document.body.removeChild(script);
    };
  }, [ak, disabled, loadedRef, loadingRef]);
  return { loaded, loading, error };
};

export function injectBMapService(
  ak: string,
  successCallback?: () => void,
  errorCallback?: () => void
) {
  if (bMapLoaded) {
    if (successCallback) successCallback();
    return;
  }
  successCallback && bMapSuccessBacks.push(successCallback);
  errorCallback && bMapErrorBacks.push(errorCallback);
  // 等待
  if (document.getElementById(scriptID)) {
    return;
  }
  // 成功
  window["MapApiLoaderCallback"] = () => {
    bMapLoaded = true;
    bMapSuccessBacks.forEach((callback) => {
      callback();
    });
    bMapSuccessBacks = [];
    bMapErrorBacks = [];
    delete window["MapApiLoaderCallback"];
  };
  // 加载
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "baidu-map-script-loader";
  script.src = `//api.map.baidu.com/api?type=webgl&v=1.0&ak=${ak}&callback=MapApiLoaderCallback`;
  // 失败
  script.onerror = function () {
    document.removeChild(script);
    bMapErrorBacks.forEach((callback) => {
      callback();
    });
    bMapSuccessBacks = [];
    bMapErrorBacks = [];
    console.error("Load baidu api error.");
  };
  document.body.appendChild(script);
}

export const injectBMapServicePromise = () => {
  return new Promise((resolve, reject) => {
    injectBMapService(
      getBMapAK(),
      () => {
        resolve(null);
      },
      () => {
        reject(new Error("Load baidu api error."));
      }
    );
  });
};

export type BMapLatLng = {
  latLng?: { lat: number; lng: number };
  latlng?: { lat: number; lng: number };
};

export type BMapLocation = {
  province: string;
  city: string;
  district: string;
  street: string;
  streetNumber: string;
  business: string;
};

export function bd09ToWGS84(lng: number, lat: number) {
  const [gcj02Lng, gcj02Lat] = bd09togcj02(lng, lat);
  return gcj02towgs84(gcj02Lng, gcj02Lat);
}

export function gcj02ToBD09(lng: number, lat: number) {
  return gcj02tobd09(lng, lat);
}
