import * as OS from "./os";

let requestAnimationFrame: (callback: any, element?: any) => {};
if (window.requestAnimationFrame) {
  requestAnimationFrame = window.requestAnimationFrame;
} else if (window.webkitRequestAnimationFrame) {
  requestAnimationFrame = window.webkitRequestAnimationFrame;
} else {
  let lastTime = 0;
  requestAnimationFrame = function (callback: any) {
    const currTime = new Date().getTime();
    const timeToCall = Math.max(0, 16 - (currTime - lastTime));
    const id = window.setTimeout(() => {
      callback(currTime + timeToCall);
    }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };
}

export function createMarkup(str: string) {
  return { __html: str };
}

export function toFixed(num: number, precision: number) {
  // 修复js toFixed精度bug
  return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(
    precision
  );
}
type KeyFn = (item: any) => string;

const defaultKeyFn: KeyFn = (item) => item.id;
export const toObject = (array: any[], keyFn: KeyFn = defaultKeyFn) =>
  array.reduce((acc: any, val: any) => ({ ...acc, [keyFn(val)]: val }), {});

export default {
  ...OS,
  requestAnimationFrame,
  createMarkup,
  toFixed,
  toObject,
};
