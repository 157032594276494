import { useEffect } from "react";
import { testMpBridge } from "utils/os";
import { isSpaceCN } from "constants/baseUrl";

export default function useGoogleTagManager() {
  useEffect(() => {
    if (testMpBridge() || isSpaceCN()) return; // block in switch app
    const GTagScript = document.createElement("script");
    GTagScript.async = true;
    GTagScript.src =
      "https://www.googletagmanager.com/gtag/js?id=UA-203807150-1";

    const configScript = document.createElement("script");
    configScript.text =
      "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-203807150-1');";

    const GTagManagerScript = document.createElement("script");
    GTagManagerScript.text =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-T529VSR');";

    // append script
    document.head.appendChild(GTagScript);
    document.head.appendChild(configScript);
    document.head.appendChild(GTagManagerScript);

    // (noah) noscript will always run by js automatically inject, so just put it in index.html
    // const NoScriptWrapper = document.createElement("noscript");
    // const iFrameEl = document.createElement("iframe");
    // iFrameEl.src = "https://www.googletagmanager.com/ns.html?id=GTM-T529VSR";
    // iFrameEl.width = "0";
    // iFrameEl.height = "0";
    // iFrameEl.style.display = "none";
    // iFrameEl.style.visibility = "hidden";
    // NoScriptWrapper.appendChild(iFrameEl);
    // const sibling = document.getElementById("root");
    // document.body.insertBefore(NoScriptWrapper, sibling);
    return () => {
      document.head.removeChild(GTagScript);
      document.head.removeChild(configScript);
      document.head.removeChild(GTagManagerScript);
      // document.body.removeChild(NoScriptWrapper);
    };
  }, []);
}
