import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Roboto, Arial, Verdana, sans-serif !important;
  }

  [class^="ant-"] {
    font-family: Roboto, Arial, Verdana, sans-serif !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: inherit;
  }

  ul {
    list-style-type: none;
  }

  .portal-notification {
    .ant-message-notice-content {
      padding: 11px 18px;
      max-width: 60vw;
    }

    &.success {
      .ant-message-notice-content {
        background-color: #EDFCF8;
      }
    }

    &.error {
      .ant-message-notice-content {
        background-color: #FFF0EF;
      }
    }

    &.warn {
      .ant-message-notice-content {
        background-color: #FFFBEB;
      }
    }
    &.info {
      .ant-message-notice-content {
        background-color: #F0F6FF;
      }
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
      padding-right: 0 !important;
    }

    .ant-notification-notice-close {
      top: 8px;
      right: 32px;
    }
  }

  .safeAreaBottomPlaceholder {
    overflow: hidden;
    display: block;
    flex: 0 0 auto;
    width: 100%;
    height: 0;
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
  }

  .safeAreaBottom {
    &::after {
      content: "";
      overflow: hidden;
      display: block;
      flex: 0 0 auto;
      width: 100%;
      height: 0;
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
    }
  }

`;

export default GlobalStyle;
