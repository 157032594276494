import React from "react";
import { useParams } from "react-router-dom";
const SimplePage = React.lazy(() => import("./SimplePage"));

// /occupancy-guide
export const OccupancyGuide = () => <SimplePage pageKey="occupancy-guide" />;

// /notice-xxx
export const Notice = () => {
  const params = useParams<{ type: string }>();
  return <SimplePage pageKey={`notice-${params.type}`} api="/switch-notices" />;
};

// /house-rules
// /house-rules-xxx
export const HouseRules = () => {
  const params = useParams<{ type: string }>();
  return (
    <SimplePage
      pageKey={params.type ? `house-rules-${params.type}` : "house-rules"}
      api="/house-rules"
    />
  );
};

// 其他动态页面
// /sp-xxx
export const SP = () => {
  const params = useParams<{ key: string }>();
  return <SimplePage pageKey={`simple-page-${params.key}`} />;
};
