import React from "react";
import intl from "@/intl/intl";
import * as SimplePages from "pages/SimplePage";

const Home = React.lazy(() => import("pages/Home"));
const Terms = React.lazy(() => import("pages/Terms"));
const Privacy = React.lazy(() => import("pages/Privacy"));
const FeeRules = React.lazy(() => import("pages/FeeRules"));
const FAQ = React.lazy(() => import("pages/FAQ"));
const Download = React.lazy(() => import("pages/Download"));
const Guide = React.lazy(() => import("pages/Guide"));
const Help = React.lazy(() => import("pages/Help"));
const Overview = React.lazy(() => import("pages/Overview"));
const Locations = React.lazy(() => import("pages/Locations"));
const Redeem = React.lazy(() => import("pages/Redeem"));
const EmailVerify = React.lazy(() => import("pages/EmailVerify"));
const NewsRoom = React.lazy(() => import("pages/NewsRoom"));
const News = React.lazy(() => import("pages/News"));
const FeedBack = React.lazy(() => import("pages/FeedBack"));
const FeedBackMP = React.lazy(() => import("pages/FeedBackMP"));
const Enterprise = React.lazy(() => import("pages/Enterprise"));
const Partner = React.lazy(() => import("pages/Partner"));
const PromoTerms = React.lazy(() => import("pages/PromoTerms"));
const BoothfansTele = React.lazy(() => import("pages/BoothfansTele"));
const LeadForm = React.lazy(() => import("pages/LeadForm"));
const LeadFormForSite = React.lazy(
  () => import("pages/LeadForm/LeadFormForSite")
);
const About = React.lazy(() => import("pages/About"));
const VerifyInvitationForEmployee = React.lazy(
  () =>
    import("@/pages/VerifyInvitationForEmployee/VerifyInvitationForEmployee")
);
const VerifyEmailAddress = React.lazy(() => import("pages/VerifyEmailAddress"));
const ConfirmBooking = React.lazy(() => import("@/pages/ConfirmBooking"));
const VoucherTAC = React.lazy(() => import("pages/VoucherTAC"));
const VoucherTACForPurchase = React.lazy(
  () => import("@/pages/VoucherTAC/VoucherTACForPurchase")
);
const ReferCompany = React.lazy(() => import("pages/ReferCompany"));
const DownloadTransition = React.lazy(() => import("pages/DownloadTransition"));
const QR = React.lazy(() => import("pages/QR"));
const ReferFriend = React.lazy(() => import("pages/ReferFriend"));
const ReferFriendSignUp = React.lazy(() => import("pages/ReferFriendSignUp"));
const TermsAndConditions = React.lazy(
  () => import("@/pages/TermsAndConditions")
);
const ReportIssue = React.lazy(() => import("@/pages/ReportIssue/ReportIssue"));
const Booth = React.lazy(() => import("pages/Booth"));
const DeeplinkLocations = React.lazy(() => import("pages/DeeplinkLocations"));
type TRoutes = Array<{
  path: string;
  component?: any;
  exact?: boolean;
  title: string;
  counts?: number;
}>;

const routes: TRoutes = [
  {
    path: "/",
    component: Home,
    exact: true,
    title: intl.formatMessage({
      defaultMessage: "Switch | On-demand Platform for Workspaces",
    }),
  },
  {
    path: "/terms-of-service/:type?/:country?",
    component: Terms,
    title: intl.formatMessage({
      defaultMessage: "Terms of Service",
    }),
  },
  {
    path: "/terms/:type?/:country?",
    component: Terms,
    title: intl.formatMessage({
      defaultMessage: "Terms of Service",
    }),
  },
  {
    path: "/privacy-policy/:country?",
    component: Privacy,
    title: intl.formatMessage({
      defaultMessage: "Privacy Policy",
    }),
  },
  {
    path: "/privacy/:country?",
    component: Privacy,
    title: intl.formatMessage({
      defaultMessage: "Switch - Privacy Policy",
    }),
  },
  {
    path: "/fee-rules",
    component: FeeRules,
    title: intl.formatMessage({
      defaultMessage: "Fee Rules",
    }),
  },
  {
    path: "/faq",
    component: FAQ,
    title: intl.formatMessage({
      defaultMessage: "FAQ",
    }),
  },
  {
    path: "/house-rules",
    component: SimplePages.HouseRules,
    title: intl.formatMessage({
      defaultMessage: "House Rules",
    }),
  },
  {
    path: "/house-rules-:type",
    component: SimplePages.HouseRules,
    title: intl.formatMessage({
      defaultMessage: "House Rules",
    }),
  },
  {
    path: "/app-download/:type?/:id?/:referenceId1?/:referenceId2",
    component: Download,
    title: intl.formatMessage({
      defaultMessage: "Download",
    }),
  },
  {
    path: "/app-download",
    component: Download,
    title: intl.formatMessage({
      defaultMessage: "Download",
    }),
  },
  {
    path: "/how-to-use-switch",
    component: Guide,
    title: intl.formatMessage({
      defaultMessage: "How to Use Switch",
    }),
  },
  {
    path: "/help",
    component: Help,
    title: intl.formatMessage({
      defaultMessage: "Customer Support",
    }),
  },
  {
    path: "/six-sense-guide",
    component: SimplePages.OccupancyGuide,
    title: intl.formatMessage({
      defaultMessage: "Occupancy Guide",
    }),
  },
  {
    path: "/_overview",
    component: Overview,
    title: intl.formatMessage({
      defaultMessage: "route overview",
    }),
  },
  {
    path: "/locations",
    component: Locations,
    title: intl.formatMessage({
      defaultMessage: "Locations",
    }),
  },
  {
    path: "/redeemvoucher",
    component: Redeem,
    title: intl.formatMessage({
      defaultMessage: "Redeemvoucher",
    }),
  },
  {
    path: "/email/:type?",
    component: EmailVerify,
    title: intl.formatMessage({
      defaultMessage: "Email Verify",
    }),
  },
  {
    path: "/news/:id?",
    component: News,
    title: intl.formatMessage({
      defaultMessage: "Switch - Newsroom",
    }),
  },
  {
    path: "/news(room)?",
    component: NewsRoom,
    title: intl.formatMessage({
      defaultMessage: "Switch - Newsroom",
    }),
  },
  {
    path: "/notice/:type(maintenance)",
    component: SimplePages.Notice,
    title: intl.formatMessage({
      defaultMessage: "Maintenance Notice",
    }),
  },
  {
    path: "/notice/:type(covid)",
    component: SimplePages.Notice,
    title: intl.formatMessage({
      defaultMessage: "COVID-19 Heightened Alert",
    }),
  },
  {
    path: "/sp/:key",
    component: SimplePages.SP,
    title: intl.formatMessage({
      defaultMessage: "Switch",
    }),
  },
  {
    path: "/feedback",
    component: FeedBack,
    title: intl.formatMessage({
      defaultMessage: "Feedback",
    }),
  },
  {
    path: "/feedback-mp",
    component: FeedBackMP,
    title: intl.formatMessage({
      defaultMessage: "Feedback",
    }),
  },
  {
    path: "/business",
    component: Enterprise,
    title: intl.formatMessage({
      defaultMessage: "Switch - Business Page",
    }),
  },
  // sfb form without navbar & footer for GTM
  {
    path: "/switchforbusiness",
    component: Enterprise,
    title: intl.formatMessage({
      defaultMessage: "Switch - Business Page",
    }),
  },
  {
    path: "/spacepartner",
    component: Partner,
    title: intl.formatMessage({
      defaultMessage: "Switch - Space Partner",
    }),
  },
  {
    path: "/lead-form",
    component: LeadForm,
    title: intl.formatMessage({
      defaultMessage: "Get in touch",
    }),
  },
  {
    path: "/switch-for-business/:source?",
    component: LeadFormForSite,
    title: "Get in touch",
  },
  {
    path: "/about",
    component: About,
    title: intl.formatMessage({
      defaultMessage: "Switch - About Page",
    }),
  },
  {
    path: "/employee-invitation",
    component: VerifyInvitationForEmployee,
    title: intl.formatMessage({
      defaultMessage: "Phone Number Submission",
    }),
  },
  {
    path: "/verify-email-address/:token?",
    component: VerifyEmailAddress,
    title: intl.formatMessage({
      defaultMessage: "Verify Email Address",
    }),
  },
  {
    path: "/confirm-booking/:detail?",
    component: ConfirmBooking,
    title: intl.formatMessage({
      defaultMessage: "Confirm Booking",
    }),
  },
  {
    path: "/voucher-terms/:type?",
    component: VoucherTAC,
    title: intl.formatMessage({
      defaultMessage: "Terms and Conditions",
    }),
  },
  {
    path: "/voucher-tac-for-purchase/:id",
    component: VoucherTACForPurchase,
    title: intl.formatMessage({
      defaultMessage: "Voucher Terms & Conditions",
      description: "route title in app",
    }),
  },
  {
    path: "/refer-company",
    component: ReferCompany,
    title: intl.formatMessage({
      defaultMessage: "Refer a Company",
    }),
  },
  {
    path: "/download-transition/:type?/:id?",
    component: DownloadTransition,
    title: intl.formatMessage({
      defaultMessage: "Download Transition",
    }),
  },
  {
    path: "/qr/*",
    component: QR,
    title: intl.formatMessage({
      defaultMessage: "QR",
    }),
  },
  {
    path: "/refer-friend",
    component: ReferFriend,
    title: intl.formatMessage({
      defaultMessage: "Refer a Friend",
    }),
  },
  {
    path: "/refer-friend-signup/:referralCode?/:countryCode?",
    component: ReferFriendSignUp,
    title: intl.formatMessage({
      defaultMessage: "Refer a Friend Sign Up",
    }),
  },
  {
    path: "/refer-terms/:key",
    component: TermsAndConditions,
    title: intl.formatMessage({
      defaultMessage: "Terms and Conditions",
    }),
  },
  {
    path: "/booth-info/:id?",
    component: Booth,
    title: intl.formatMessage({
      defaultMessage: "booth info",
    }),
  },
  {
    path: "/report-issue",
    component: ReportIssue,
    title: intl.formatMessage({
      defaultMessage: "Report Issue",
    }),
  },
  {
    path: "/promoterms",
    component: PromoTerms,
    title: intl.formatMessage({
      defaultMessage: "Switch - Promo Terms",
    }),
  },
  {
    path: "/boothfans-tele",
    component: BoothfansTele,
    title: intl.formatMessage({
      defaultMessage: "Switch - Join our Telegram Channel!",
    }),
  },
  {
    path: "/invited-booking-location",
    component: DeeplinkLocations,
    title: intl.formatMessage({
      defaultMessage: "Download",
    }),
  },
];

export default routes;
