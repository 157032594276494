export const hack = () => {
  // prerender时， 告诉 style components 渲染 style 标签
  if (/prerender/i.test(window.navigator.userAgent)) {
    (window as any).SC_DISABLE_SPEEDY = true;
  }
};

// 告诉 prerender，页面渲染完毕
export const prerenderReady = () => {
  (window as any).prerenderReady = true;
};

hack();

// prerender ready 最大等待时间，3 分钟
setTimeout(() => {
  (window as any).prerenderReady = true;
}, 180000);
