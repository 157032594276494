import { LangType } from "constants/language";
import { getLanguage } from "@/intl/language";

export const getBaseUrl = (): string => {
  let url = process.env.REACT_APP_API_BASE_URL;
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

export const getCMSBaseUrl = (): string => {
  let url = process.env.REACT_APP_API_CMS;
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

export const getMPBaseUrl = (): string => {
  let url = process.env.REACT_APP_API_MP || "";
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

export const getSPBaseUrl = (lang?: LangType): string => {
  const useLang = lang || getLanguage();
  let url = process.env.REACT_APP_SPACE_PARTNER_URL;
  // 中国域名访问 switchspace.cn
  if (useLang === LangType.ZH) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

export const getSFBBaseUrl = (lang?: LangType): string => {
  const useLang = lang || getLanguage();
  let url = process.env.REACT_APP_SWITCH_FOR_BUSNIESS_URL;
  // 中国域名访问 switchspace.cn
  if (useLang === LangType.ZH) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};

export const isSpaceCN = () => {
  return /switchspace.cn/.test(document.domain);
};

export const getWebLinkUrlInApp = (countryCode: string): string => {
  let url = process.env.REACT_APP_WEB_LINKS;
  // 中国域名访问 switchspace.cn
  if (countryCode === "CN") {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};
export const getWebLinkUrl = (): string => {
  let url = process.env.REACT_APP_WEB_LINKS;
  // 中国域名访问 switchspace.cn
  if (/switchspace.cn/.test(document.domain)) {
    url = url.replace(/switch.site/, "switchspace.cn");
  }
  return url;
};
