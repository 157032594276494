import queryString from "query-string";

export const joinUrl = (...paths: string[]) => {
  const url = paths.join("/");
  return url.replace(/(^|\w)[/]{2,}/g, "$1/");
};

export const addSearchQuery = (
  search: string,
  params: Record<string, string | number>
) => {
  const p = queryString.parse(search);
  return `?${queryString.stringify({ ...p, ...params })}`;
};
