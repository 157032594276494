import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { languages, defaultLanguage } from "@/intl/language";
import { useLanguage } from "@/intl/intl";

export default function useSEO() {
  const location = useLocation();
  const [lang] = useLanguage();

  useEffect(() => {
    const loc = window.location;
    const olds = document.querySelectorAll(`link[hreflang][rel="alternate"]`);
    olds.forEach((elem) => elem.remove());

    if (loc.search.indexOf("lang=") !== -1) return;

    const langs = languages.map((l) => {
      let path = location.pathname;
      if (path.indexOf(`/${lang}/`) === 0) {
        path = path.substring(lang.length + 1);
      }
      if (l !== defaultLanguage) {
        path = `/${l}${path}`;
      }
      const url = `${loc.origin}${path}${loc.search}${loc.hash}`;
      return { lang: l, url };
    });

    langs.forEach((v) => {
      const link = document.createElement("link");
      link.setAttribute("rel", "alternate");
      link.setAttribute("hreflang", v.lang);
      link.setAttribute("href", v.url);
      document.head.appendChild(link);
    });
  }, [lang, location]);
}
