import React, { useMemo } from "react";
import {
  IntlShape,
  RawIntlProvider,
  IntlConfig,
  createIntlCache,
  createIntl,
} from "react-intl";
import { useBehavior } from "utils/@reactive-service/react/src";

import { LangType } from "constants/language";
import language from "./language";
import enUSJson from "./langs/en-US.json";
import zhHansJson from "./langs/zh-Hans.json";
import koKRJson from "./langs/ko-KR.json";
import zhHantJson from "./langs/zh-Hant-HK.json";
import jaJson from "./langs/ja.json";
// import zhTWJson from "./langs/zh-Hant-TW.json";
// import thTHJson from "./langs/th-TH.json";

const createPerIntl = (locale: string, messages: IntlConfig["messages"]) => {
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  return createIntl(
    {
      locale,
      messages,
      onError:
        process.env.NODE_ENV === "development"
          ? () => {
              /** disable intl error in develop */
              return null;
            }
          : undefined,
    },
    cache
  );
};

const language2Intl: { [key: string]: any } = {
  "en-US": createPerIntl("en-US", enUSJson),
  "zh-Hans": createPerIntl("zh-Hans", zhHansJson),
  "ko-KR": createPerIntl("ko-KR", koKRJson),
  "zh-Hant-HK": createPerIntl("zh-Hant-HK", zhHantJson),
  ja: createPerIntl("ja", jaJson),
  // "zh-Hant-TW": createPerIntl("zh-Hant-TW", zhTWJson),
  // "th-TH": createPerIntl("th-TH", thTHJson),
};

export const getIntl = (lang: string) => language2Intl[lang];

export function IntlProvider({ children }: React.PropsWithChildren<{}>) {
  language.initIfNeed();
  const lang = useBehavior(language.$current);
  const intl = useMemo(() => {
    return getIntl(lang);
  }, [lang]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}

const intl = {
  formatMessage: (...args: Parameters<IntlShape["formatMessage"]>) => {
    return getIntl(language.current).formatMessage(...args);
  },
};

const useLanguage = (): [
  LangType,
  (lang: LangType, reload?: boolean) => void
] => {
  const lang = useBehavior(language.$current);
  const setLang = (lang: LangType, reload = false) => {
    // 更改语言，直接刷新
    if (reload) {
      language.changeLanguage(lang);
      return;
    }
    language.setCurrent(lang);
  };
  return [lang, setLang];
};

const useIsPathLanguage = (): boolean => {
  const isPathLanguage = useBehavior(language.$isPathLanguage);
  return isPathLanguage;
};

const getDefaultCountry = () => {
  const { lang } = language.getLanguage();
  switch (lang) {
    case LangType.ZH:
      return {
        country: "CN",
        countryCode: "+86",
      };
    case LangType.HK:
      return {
        country: "HK",
        countryCode: "+852",
      };
    case LangType.JA:
      return {
        country: "JP",
        countryCode: "+81",
      };
    default:
      return {
        country: "SG",
        countryCode: "+65",
      };
  }
};

export default intl;
export { useLanguage, useIsPathLanguage, getDefaultCountry };
