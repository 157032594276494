import { Service } from "utils/@reactive-service/react/src";
import language, { LangSelects } from "intl/language";

type IntlServiceState = {
  langs: typeof LangSelects;
  language: string;
};

export default class IntlService extends Service<IntlServiceState> {
  constructor() {
    super({
      state: {
        langs: LangSelects,
        language: language.current,
      },
    });

    this.subscribe(language.$current, {
      next: (v) => {
        this.$s.language.next(v);
      },
    });
  }
}
