import { useState, useCallback, useEffect } from "react";
import { createContainer } from "unstated-next";

import { IAccessToken } from "constants/types";
import * as apiAccessToken from "service/request/accessToken";
import { token } from "service/v1/identity";
import tokenKey from "service/request/tokenKey";
const storedToken = tokenKey ? localStorage.getItem(tokenKey()) : undefined;
const defaultToken = storedToken ? JSON.parse(storedToken) : null;

const container = createContainer(() => {
  const [accessToken, setAccessToken] = useState<IAccessToken | null>(
    defaultToken
  );

  const clearToken = useCallback(() => {
    apiAccessToken.clearToken();
    setAccessToken(null);
  }, []);
  const setToken = useCallback((token: IAccessToken) => {
    apiAccessToken.setToken(token);
    setAccessToken(token);
  }, []);
  const refreshTokenGenerator = useCallback(async () => {
    try {
      const tokenValue = await token();
      setAccessToken(tokenValue as IAccessToken);
      return tokenValue;
    } catch (e) {
      clearToken();
    }
  }, [setAccessToken, clearToken]);

  useEffect(() => {
    apiAccessToken.configureTokenRefresh(refreshTokenGenerator);
    return () => {
      apiAccessToken.configureTokenRefresh(undefined);
    };
  }, [refreshTokenGenerator]);

  return {
    isLogin: accessToken !== undefined && accessToken !== null,
    isInitialized: accessToken !== undefined,
    accessToken,
    clearToken,
    setToken,
  };
});

export const AccessTokenProvider = container.Provider;

export const useAccessToken = container.useContainer;

export default container;
