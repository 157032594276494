import { IAccessToken } from "constants/types";
import request from "../request";
import * as Types from "constants/types";

export type ISMSRequest = {
  countryCode: string;
  mobile: string;
  nonce: string;
  signature: string;
};

export const smsRequest = async (data: ISMSRequest) => {
  return await request({
    method: "POST",
    path: "/v1/mobile/verification-code",
    data,
    noToken: true,
  });
};

export type ILoginRequestParam = {
  countryCode: string;
  mobile: string;
  smsCode: string;
  channel?: string;
  partnerUid?: string;
};

export const login = async (data: ILoginRequestParam) => {
  return await request<IAccessToken>({
    method: "POST",
    path: "/v1/mobile/login/web?setCookie=true",
    data,
    noToken: true,
    includeCredentials: true,
  });
};

export const logout = async () => {
  return await request({
    method: "DELETE",
    path: "/v1/logout",
    refreshOnUnauthorized: true,
    includeCredentials: true,
  });
};

export const token = async () => {
  return await request<IAccessToken>({
    method: "POST",
    path: "/v2/refresh-token",
    includeCredentials: true,
    noToken: true,
  });
};

export const countryInfoList = async () => {
  return await request<Types.ICountryCode[]>({
    path: "/v1/country-code",
    method: "GET",
  });
};
export type ISubmitRequestParam = {
  countryCode: string;
  mobile: string;
  smsCode: string;
  grantType: string;
  memberId: string;
};
export const submitPhone = async (data: ISubmitRequestParam) => {
  return await request<IAccessToken>({
    method: "POST",
    path: "/v1/mobile/login/web?setCookie=true",
    data,
    noToken: true,
    includeCredentials: true,
  });
};
export type IExpiredStatus = {
  expired: boolean;
  expiredTargetLink: string;
  activated: boolean;
  organizationAdminEmail: string;
};

export const expiredStatus = async (data: {
  memberId: string;
  invitationToken: string;
}) => {
  return await request<IExpiredStatus>({
    method: "POST",
    path: "/v1/public/check-invitation-email-expired",
    data,
    refreshOnUnauthorized: true,
    includeCredentials: true,
  });
};
