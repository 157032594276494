import { BehaviorSubject } from "rxjs";
import queryString from "query-string";
import {
  LangType,
  languages,
  defaultLanguage,
  languagesMap,
  LangSelects,
} from "constants/language";
import { testMpBridge } from "utils/os";

const normalizeLanguage = (lang: string) => {
  // 精确匹配：en-US => en-US
  const arr = languages.map((item) => item.toLocaleLowerCase());
  const i = arr.indexOf(lang.toLocaleLowerCase());
  if (i !== -1) return languages[i];

  // 映射
  for (let item of languagesMap) {
    if (item[0].test(lang) && languages.includes(item[1])) return item[1];
  }

  // 默认值
  return "";
};

class Language {
  // 当前设置过的语言
  inited = false;
  current: string = defaultLanguage;
  $isPathLanguage = new BehaviorSubject(false);
  $current: BehaviorSubject<LangType> = new BehaviorSubject(defaultLanguage);

  constructor() {
    this.initIfNeed();
  }

  // 获取path中的语言, 必须精确匹配才能当做语言，否则当成普通路径
  getPathLanguage(location: Partial<Location> = window.location) {
    const path = location.pathname || "";
    const first = path.split("/")[1] || "";
    if (languages.includes(first as LangType)) return first;
    return "";
  }

  // 获取参数中的语言, 必须精确匹配才能当做语言，否则当成普通路径
  getQueryLanguage(location: Partial<Location> = window.location) {
    const query = queryString.parse(location.search || "");
    if (query["market"] && /switchforbusiness/.test(location.pathname || "")) {
      // 20220111, noah, for GTM request
      // https://justcoglobal.atlassian.net/browse/RS-3970
      switch (query["market"]) {
        default:
          break;
        case "SG":
          return "en-US";
        case "HK":
          return "zh-Hant-HK";
      }
    }
    return (query["lang"] || query["language"] || "") as string;
  }

  // 获取浏览器语言
  getBrowserLanguage() {
    return (
      navigator.language || (window.navigator as any).browserLanguage || ""
    );
  }

  // 获取上一次切换的语言
  getStoredLanguage() {
    const lang = localStorage.getItem("language");
    return lang;
  }

  // get cur lang
  // return {lang, type}
  getLanguage(args?: {
    types?: string[];
    defaultLanguage?: LangType;
    location: Partial<Location>;
  }) {
    const isApp = testMpBridge();

    let {
      // "path", "query", "store", "browser"
      types = isApp
        ? ["path", "query", "browser", "store"]
        : ["path", "query", "store"],
      // need to go to optimization
      defaultLanguage: def = isApp ? LangType.EN : defaultLanguage,
      location = window.location,
    } = args || {};

    let langs: Record<string, string>;
    let lang: string;
    let useType: string | null = null;

    langs = {
      path: this.getPathLanguage(location) || "",
      query: this.getQueryLanguage(location) || "",
      browser: this.getBrowserLanguage() || "",
      store: this.getStoredLanguage() || "",
    };
    lang = "";
    types.forEach((type) => {
      if (lang) return;
      if (langs[type]) {
        const res = normalizeLanguage(langs[type]);
        if (res) {
          useType = type;
          lang = res;
        }
      }
    });

    return { lang: (lang as LangType) || def, type: useType as string | null };
  }

  setCurrent(lang: LangType, store = true) {
    if (languages.indexOf(lang) === -1) {
      console.error(`error lang ${lang}`);
      return;
    }
    this.current = lang;
    this.$current.next(lang);
    store && localStorage.setItem("language", lang);
    !store && sessionStorage.setItem("temp_language", lang);
  }

  changeLanguage(lang: LangType) {
    localStorage.setItem("language", lang);
    window.location.reload();
  }

  initIfNeed() {
    if (this.inited) return;
    const { lang, type } = this.getLanguage();
    if (sessionStorage.getItem("temp_language")) {
      // Refreshing from the link with params no longer resets the language
      const store_lang = this.getStoredLanguage();
      store_lang && this.setCurrent(store_lang as LangType);
      this.inited = true;
      return;
    }
    this.$isPathLanguage.next(type === "path");
    this.setCurrent(lang, type !== "query");
    this.inited = true;
  }
}

const language = new Language();

export const getLanguage = () => {
  return language.current;
};

export default language;
export { LangType, languages, defaultLanguage, languagesMap, LangSelects };
