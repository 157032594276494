import React, { useRef, useEffect, useMemo } from "react";
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  RouteProps,
} from "react-router-dom";
import { config, ServiceInjector } from "utils/@reactive-service/react/src";

import DeviceService from "service/device.service";
import { IntlProvider, useLanguage, useIsPathLanguage } from "intl/intl";
import { LangType } from "constants/language";
import { AccessTokenProvider } from "context/accessToken";
import IntlService from "service/intl.service";
import "normalize.css";
import "./fonts/fonts.css";
import AppStyle from "./App.style";

import routes from "./routes";

// import useHubSpot from "hooks/track/useHubSpot";
import useSEO from "hooks/useSEO";
import useGoogleTagManager from "hooks/track/useGoogleTagManager";
import { injectBMapService, getBMapAK } from "hooks/map/useBMapApiLoader";
import { isSpaceCN } from "constants/baseUrl";
import { joinUrl } from "utils/url";

// whilte, weinre console.log to string bug
config({
  log(msg) {
    // try {
    //   console.log(msg);
    // } catch (e) {
    //   console.log(msg + "");
    // }
  },
});

const SuspenseFallback = <div />;

function ProviderWrapper() {
  const providers = useRef([DeviceService, IntlService]);
  // useHubSpot(); 后端集成
  useGoogleTagManager();
  if (isSpaceCN()) injectBMapService(getBMapAK());
  return (
    <>
      <IntlProvider>
        <ServiceInjector providers={providers.current}>
          <AccessTokenProvider>
            <App />
          </AccessTokenProvider>
        </ServiceInjector>
      </IntlProvider>
      <AppStyle key="app-style" />
    </>
  );
}

const SEO = () => {
  useSEO();
  return null;
};

function App() {
  const [lang] = useLanguage();
  const isPathLanguage = useIsPathLanguage();
  const baseName = useMemo(() => {
    const baseUrl = process.env.PUBLIC_URL;
    if (isPathLanguage) return joinUrl(baseUrl, lang);
    return baseUrl;
  }, [isPathLanguage, lang]);

  useEffect(() => {
    const html = document.getElementsByTagName("html");
    if (html.length) {
      html[0].setAttribute("lang", lang);
    }
  }, [lang]);

  return (
    <>
      <BrowserRouter basename={baseName}>
        <SEO />
        <React.Suspense fallback={SuspenseFallback}>
          <Switch>
            <Redirect from="/enterprise" to="/business" />
            {routes.map((i) => (
              <WrapperRoute
                key={i.path}
                path={i.path}
                component={i.component}
                exact={i.exact}
                title={i.title}
              />
            ))}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      {lang === LangType.ZH && (
        <div style={{ width: 0, height: 0, overflow: "hidden" }}>
          此处为，预加载字体用，不能删除
          {navigator.userAgent}
        </div>
      )}
    </>
  );
}

function WrapperRoute({ title, ...rest }: RouteProps & { title?: string }) {
  document.title = title || " ";
  return <Route {...rest} />;
}

export default ProviderWrapper;
