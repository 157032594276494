import { fromEvent } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { Service } from "utils/@reactive-service/react/src";
import utils from "utils";

type DeviceStatus = {
  width: number;
  height: number;
};

type DeviceConfig = {
  minClientWidth: number;
  breakpoints: { point: string; value: number }[];
};

const { testMobile } = utils;
const deviceConfig: DeviceConfig = {
  minClientWidth: 768,
  breakpoints: [
    { value: 1339, point: "xl" },
    { value: 1119, point: "lg" },
    { value: 1024, point: "md" },
    { value: 767, point: "sm" },
    { value: 575, point: "xs" },
  ],
};

export const testMpBridge = () => {
  if ((window as any).__wxjs_environment === "miniprogram") return true;
  const bridge =
    (window as any).internalMiniAppJsBridge ||
    (window as any).webkit?.messageHandlers?.internalMiniAppJsBridge;
  return typeof bridge === "object" && bridge !== null;
};

export const testBreakpoints = () => {
  const w = window.innerWidth;
  let breakpoints: string[] = [];
  deviceConfig.breakpoints.forEach((item) => {
    if (w <= item.value) breakpoints.push(item.point);
  });
  return breakpoints;
};

export const testBreackPoint = (p: string) => {
  const find = deviceConfig.breakpoints.find((item) => item.point === p);
  if (!find) return false;
  const w = window.innerWidth;
  return w <= find.value;
};

export type DeviceServiceState = {
  config: DeviceConfig;
  status: DeviceStatus;
  isMobile: boolean;
  isMpBridge: boolean;
  breakpoints: string[];
  isXL: boolean;
  isLG: boolean;
  isMD: boolean;
  isSM: boolean;
  isXS: boolean;
};

export type DeviceServiceActions = {};

export default class DeviceService extends Service<
  DeviceServiceState,
  DeviceServiceActions
> {
  displayName = "DeviceService";

  constructor() {
    super({
      state: {
        config: deviceConfig,
        status: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        isMobile: testMobile(deviceConfig.minClientWidth),
        isMpBridge: testMpBridge(),
        breakpoints: testBreakpoints(),
        isXL: testBreackPoint("xl"),
        isLG: testBreackPoint("lg"),
        isMD: testBreackPoint("md"),
        isSM: testBreackPoint("sm"),
        isXS: testBreackPoint("xs"),
      },
    });

    const resize$ = fromEvent(window, "resize").pipe(
      debounceTime(50),
      map(() => {
        this.$s.status.next({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        this.$s.isMobile.next(testMobile(deviceConfig.minClientWidth));
        this.$s.breakpoints.next(testBreakpoints());
        this.$s.isXL.next(testBreackPoint("xl"));
        this.$s.isLG.next(testBreackPoint("lg"));
        this.$s.isMD.next(testBreackPoint("md"));
        this.$s.isSM.next(testBreackPoint("sm"));
        this.$s.isXS.next(testBreackPoint("xs"));
      })
    );
    this.subscribe(resize$);
  }
}
